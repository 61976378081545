@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";






























































section {
  background: var(--gradient);

  .imgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    @include mq($until: sm) {
      display: none;
    }

    & > * {
      position: absolute;
    }

    .rhombus {
      top: 58.011049723757%;
      left: 14.641288433382%;
      width: 7.320644216691%;
    }

    .pacman {
      top: 28.360957642726%;
      right: 10.175695461201%;
      width: 12.884333821376%;
    }
  }

  .parallax {
    position: absolute;
    top: -2%;
    left: 0;
    pointer-events: none;
    z-index: 4;
    max-width: 16.166788588149%;

    @include mq($until: sm) {
      max-width: 40%;
    }
  }

  .video {
    position: relative;
    grid-column: 1 / -1;
    height: 0;
    padding-bottom: 150%;
    border-radius: 15px;

    @include mq(sm) {
      grid-column: 5 / 9;
    }
  }

  .play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;
    transition: opacity 0.5s var(--ease);

    img {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--color-primary);
      opacity: 0.5;
      pointer-events: none;
      border-radius: 15px;
    }
    &.hide {
      opacity: 0;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .video__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1.2s var(--ease);

    &.scale {
      transform: scale(0.9);
    }
  }

  .divider {
    grid-column: 1 / -1;
    @include mq(sm) {
      grid-column: 5 / 9;
    }

    img {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  .text__wrapper {
    grid-column: 1 / -1;
    @include mq(sm) {
      grid-column: 3 / 11;
    }
  }

  .text {
    margin: 0 auto;
    max-width: 620px;

    ::v-deep p {
      @extend %typo--sm-2;
    }
  }
}
