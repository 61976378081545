@each $mq-key, $mq-value in $mq-breakpoints {
  .m-t-#{$mq-key}-0 {
    @include mq($mq-key) {
      margin-top: 0;
    }
  }
  .m-t-#{$mq-key}-xxs {
    @include mq($mq-key) {
      margin-top: var(--spacer-xxs);
    }
  }
  .m-t-#{$mq-key}-xs {
    @include mq($mq-key) {
      margin-top: var(--spacer-xs);
    }
  }
  .m-t-#{$mq-key}-sm {
    @include mq($mq-key) {
      margin-top: var(--spacer-sm);
    }
  }
  .m-t-#{$mq-key}-md {
    @include mq($mq-key) {
      margin-top: var(--spacer-md);
    }
  }
  .m-t-#{$mq-key}-lg {
    @include mq($mq-key) {
      margin-top: var(--spacer-lg);
    }
  }
  .m-t-#{$mq-key}-xl {
    @include mq($mq-key) {
      margin-top: var(--spacer-xl);
    }
  }
  .m-t-#{$mq-key}-xxl {
    @include mq($mq-key) {
      margin-top: var(--spacer-xxl);
    }
  }
  .m-b-#{$mq-key}-xxs {
    @include mq($mq-key) {
      margin-bottom: var(--spacer-xxs);
    }
  }
  .m-b-#{$mq-key}-xs {
    @include mq($mq-key) {
      margin-bottom: var(--spacer-xs);
    }
  }
  .m-b-#{$mq-key}-sm {
    @include mq($mq-key) {
      margin-bottom: var(--spacer-sm);
    }
  }
  .m-b-#{$mq-key}-md {
    @include mq($mq-key) {
      margin-bottom: var(--spacer-md);
    }
  }
  .m-b-#{$mq-key}-lg {
    @include mq($mq-key) {
      margin-bottom: var(--spacer-lg);
    }
  }
  .m-b-#{$mq-key}-xl {
    @include mq($mq-key) {
      margin-bottom: var(--spacer-xl);
    }
  }
  .m-b-#{$mq-key}-xxl {
    @include mq($mq-key) {
      margin-bottom: var(--spacer-xxl);
    }
  }
  .p-t-#{$mq-key}-xxs {
    @include mq($mq-key) {
      padding-top: var(--spacer-xxs);
    }
  }
  .p-t-#{$mq-key}-xs {
    @include mq($mq-key) {
      padding-top: var(--spacer-xs);
    }
  }
  .p-t-#{$mq-key}-sm {
    @include mq($mq-key) {
      padding-top: var(--spacer-sm);
    }
  }
  .p-t-#{$mq-key}-md {
    @include mq($mq-key) {
      padding-top: var(--spacer-md);
    }
  }
  .p-t-#{$mq-key}-lg {
    @include mq($mq-key) {
      padding-top: var(--spacer-lg);
    }
  }
  .p-t-#{$mq-key}-xl {
    @include mq($mq-key) {
      padding-top: var(--spacer-xl);
    }
  }
  .p-b-#{$mq-key}-xxs {
    @include mq($mq-key) {
      padding-bottom: var(--spacer-xxs);
    }
  }
  .p-b-#{$mq-key}-xs {
    @include mq($mq-key) {
      padding-bottom: var(--spacer-xs);
    }
  }
  .p-b-#{$mq-key}-sm {
    @include mq($mq-key) {
      padding-bottom: var(--spacer-sm);
    }
  }
  .p-b-#{$mq-key}-md {
    @include mq($mq-key) {
      padding-bottom: var(--spacer-md);
    }
  }
  .p-b-#{$mq-key}-lg {
    @include mq($mq-key) {
      padding-bottom: var(--spacer-lg);
    }
  }
  .p-b-#{$mq-key}-xl {
    @include mq($mq-key) {
      padding-bottom: var(--spacer-xl);
    }
  }
}