@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";














































section {
  background: var(--color-alt);

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 50%;
    background: var(--gradient-reverse);
    transform: translateZ(0px);
  }

  .title {
    position: relative;
    z-index: 1;
    
    text-align: left;

    @include mq(sm) {
      text-align: center;
      padding-top: calc(var(--vh) * 12.19512195122 + var(--spacer-lg));
    }
  }

  .imgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;

    @include mq($until: sm) {
      display: none;
    }


    & > * {
      position: absolute;
    }

    .shape {
      top: calc(var(--vh) * 2);
      left: 66.251830161054vw;
      width: 15.08345534407vw;
      height: calc(var(--vh) * 12.19512195122);
    }
  }

  .slider {
    padding-bottom: calc(16px + var(--spacer-md) * 2);
  }

  .item {
    background: var(--color-primary);
    padding: var(--spacer-sm);
    width: 90%;
    min-width: 300px;
    margin-right: var(--spacer-lg);
    min-height: 100%;

    @include mq(sm) {
      width: 25%;
      margin-right: var(--spacer-xl);
      padding: 4vw;
    }

    &__title {
      ::v-deep h4 {
        line-height: 1;
      }
      ::v-deep em {
        display: block;
      }
    }

    img {
      height: 120px;
      max-width: 100%;
      margin: 4vw auto;
    }
  }
}
