.grid {
  display: grid;
  width: 100%;
  column-gap: var(--spacer-xxs);
  row-gap: var(--spacer-xxs);
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.g-12 {
  grid-template-columns: repeat(12, 1fr);
}
.g-a {
  grid-template-columns: auto;
}
.g-c-g-0 {
  column-gap: 0;
}
.order-2 {
  order: 2;
}

@each $mq-key, $mq-value in $mq-breakpoints {
  .g-#{$mq-key}-1 {
    @include mq($mq-key) {
      grid-template-columns: 1fr;
    }
  }
  .g-#{$mq-key}-2 {
    @include mq($mq-key) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .g-#{$mq-key}-3 {
    @include mq($mq-key) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .g-#{$mq-key}-4 {
    @include mq($mq-key) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .g-#{$mq-key}-5 {
    @include mq($mq-key) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

}