@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";






































































































































































































































































































#paypal-button-container {
  transition: 0.8s opacity var(--ease);
}
.paypal-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;

  * {
    pointer-events: none;
    cursor: not-allowed !important;
  }
}

.select__wrapper {
  display: block;
  position: relative;

  &::after {
    position: absolute;
    right: 7px;
    top: 0;
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 1;
    pointer-events: none;
    opacity: 0.7;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z'/%3E%3C/svg%3E");

    @include mq(sm) {
      right: 14px;
    }
  }
  /* &:last-child {
    width: 20%;
    text-align: center;
  } */
}
select, input {
  display: block;
  position: relative;
  appearance: none;
  border: 0;
  height: 45px;
  width: 100%;
  padding: 0 15px;
  font-family: var(--font-base);
  font-size: 16px;
  background: var(--color-white);
  border-radius: 23px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 0s 50000s;
  }

  @include mq(sm) {
    height: 55px;
    padding: 0 20px;
  }
  border-bottom: 3px solid transparent;
  &.active {
    border-bottom-color: var(--color-primary);
  }
}

.small {
  p {
    font-size: 80%;
    line-height: 1.6em;

    a {
      color: var(--color-primary);
    }
  }
}
