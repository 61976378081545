@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";










































































































footer {
  position: relative;
  background: var(--color-primary);

  padding: var(--spacer-lg) var(--spacer-md) var(--spacer-md) var(--spacer-md);

  @include mq(sm) {
    padding: var(--spacer-xxl) var(--spacer-xl) var(--spacer-lg) var(--spacer-xl);
  }
}

::v-deep h4 {
  @extend %typo--xxs;
  text-transform: none;
}

.pattern {
  top: 8px;
  left: 0;
  position: absolute;
  width: 100%;
  height: 56px;
  background-repeat: repeat-x;
  background-size: auto 100%;
}

.grid {
  padding-top: 64px;
}

.brand {
  img {
    max-width: 216px;
    margin-bottom: var(--spacer-xxs);
  }
}
.current {
  pointer-events: none;
  color: var(--color-purple);

  &::after {
    transform: scaleX(1);
  }
}

.payoff {
  font-weight: var(--weight-demi);
}

.column {
  grid-column: 1 / -1;
  
  @include mq(sm) {
    grid-column: auto / span 3;
  }
}

.column-0 {
  @include mq(sm) {
    grid-column: 1 / 3;
  }
}

.column-1 {
  @include mq(sm) {
    grid-column: 4 / 7;
  }
}

.text {
  max-width: 80%;
}

.extra {
  grid-column: 1 / -1;
}

.brand,
.social {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 1 / 4;
  }
}

.lang {
  grid-column: 1 / -1;
  line-height: 24px;
  margin-bottom: var(--spacer-xs);
  @include mq(sm) {
    margin-bottom: 0;
  }

  .flex li + li {
    margin-left: var(--spacer-xs);
    flex: 1 0 0;
  }
}

.social {
  li + li {
    margin-left: var(--spacer-xs);
  }
}

.extra {
  li + li {
    flex: 0 0 100%;
    @include mq(sm) {
      flex: 1 0 0;
      margin-left: var(--spacer-md);
    }
  }
}
