@import '~sass-mq';

$mq-responsive: true;
$mq-breakpoints: (
  xxxs: 320px,
  xxs: 375px,
  xs: 414px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1680px,
  xxxl: 1920px,
  xxxxl: 2560px,
);

$is-landscape: '(orientation: landscape)';
$is-portrait: '(orientation: portrait)';
$is-iphonex: '(device-height: 812px) and (-webkit-device-pixel-ratio : 3)';
$is-ipadpro: '(max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)';
$is-touch-device: '(pointer: coarse) and (hover: none)';
$is-hover: '(pointer: fine) and (hover: hover)';
$is-ie-11: '(-ms-high-contrast: active) and (-ms-high-contrast: none)';
$is-height-small: '(max-height: 767px)';

$layers: (
  below: -1,
  base: 0,
  above: 1,
  modal: 50,
  header: 30,
  footer: 25,
  loader: 100,
);
:root {
  --color-primary: #008E6E;
  --color-alt: #A6D4C2;
  --color-muted: #F4FAED;
  --color-accent: #85FFBD;
  --color-dark: #232323;
  --color-dark-grey: #434343;
  --color-purple: #47296F;
  --color-light: #FAFAFA;
  --color-white: #ffffff;

  --gradient: linear-gradient(180deg, var(--color-white) 0%, var(--color-light) 49%, var(--color-muted) 100%);
  --gradient-reverse: linear-gradient(0deg, var(--color-white) 0%, var(--color-light) 49%, var(--color-muted) 100%);

  --font-base: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-head: 'Inknut Antiqua', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  --weight-regular: 400;
  --weight-demi: 600;
  --weight-bold: 700;

  --style-italic: italic;

  --typo-xxxl: 100px;
  --typo-xxl: 48px;
  --typo-xl: 36px;
  --typo-lg: 32px;
  --typo-md: 20px;
  --typo-sm: 16px;
  --typo-xs: 14px;
  --typo-xxs: 12px;

  @include mq($until: sm) {
    --typo-xxl: 30px;
    --typo-xl: 24px;
    --typo-lg: 20px;
    --typo-md: 18px;
  }

  --spacer-xxl: 64px;
  --spacer-xl: 48px;
  --spacer-lg: 40px;
  --spacer-md: 32px;
  --spacer-sm: 24px;
  --spacer-xs: 16px;
  --spacer-xxs: 8px;

  --wrapper-xl: 1920px;
  --wrapper-lg: 1440px;
  --wrapper-md: 1080px;
  --wrapper-sm: 960px;

  @include mq(md) {
  }

  --vh: 1vh;
  --ease: cubic-bezier(.19, 1, .22, 1);
  --logo-height: 34px;
  --header-height: calc(var(--logo-height) + (var(--spacer-sm) * 2))
}

@mixin transition($args: all) {
  transition: $args 0.5s var(--ease);
}