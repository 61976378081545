.flex {
  display: flex;
  flex-flow: row wrap;
}
.fd-c {
  flex-direction: column;
}
.jc-sb {
  justify-content: space-between;
}
.jc-c {
  justify-content: center;
}
.jc-e {
  justify-content: flex-end;
}
.ai-s {
  align-items: flex-start;
}
.ai-c {
  align-items: center;
}
.ai-b {
  align-items: baseline;
}
.ai-e {
  align-items: flex-end;
}
.as-s {
  align-self: flex-start;
}
.as-c {
  align-self: center;
}
.as-e {
  align-self: flex-end;
}
.fw-nw {
  flex-wrap: nowrap;
}

@each $mq-key, $mq-value in $mq-breakpoints {
  .f-#{$mq-key}-2 {
    @include mq($mq-key) {
      flex: 0 0 50%;
    }
  }
}
