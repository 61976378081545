@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";

























































































































































































@import '@/assets/scss/core/_base.scss';
@import '@/assets/scss/core/_utils.scss';
@import '@/assets/scss/core/_flex.scss';
@import '@/assets/scss/core/_grid.scss';
@import '@/assets/scss/core/_spacer.scss';

main {
  overflow: hidden;
}

body {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    pointer-events: none;
    transition: 0.8s opacity var(--ease);
  }
  &.is-modal {
    height: calc(var(--vh) * 100);
    overflow: hidden;

    &::after {
      pointer-events: auto;
      opacity: 1;
      z-index: 19;
    }
  }
}

section {
  position: relative;
  padding: var(--spacer-md);

  @include mq(sm) {
    padding: var(--spacer-xl);
  }
}

a:not(.default) {
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 1s var(--ease);
    transform-origin: 0% 50%;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}

h1 {
  position: relative;
  @extend %typo--xxl;

  @include mq(sm) {
    &::before {
      content: '';
      width: 10px;
      height: 95%;
      position: absolute;
      top: 2.5%;
      left: calc(var(--spacer-md) * -1);
      background: currentColor;
    }
  }

}

h2 {
  @extend %typo--xl;
}

h3 {
  @extend %typo--lg;
}

h4 {
  @extend %typo--md;
  em {
    font-style: normal;
  }
}

h5 {
  @extend %typo--md-2;
}

.text {
  max-width: 530px;
}

p {
  @extend %typo--sm;
}

.flickity-viewport {
  overflow: visible;
}
.flickity-page-dots {
  bottom: -16px;
  padding: var(--spacer-lg) 0;

  .dot {
    border-radius: 0;
    transform: rotate(-45deg);
    background: var(--color-white);
    opacity: 1;

    &.is-selected {
      background: var(--color-accent);
    }
  }
}

[data-scroll-container] {
  will-change: transform;
}

[data-anim] {
  will-change: transform, opacity;
}
[data-mouse] {
  pointer-events: auto;
  z-index: 20;
  will-change: transform;

  &::after {
    content: '';
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    position: absolute;
  }

  * {
    pointer-events: none;
  }
}
