@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";







































section {
  background: var(--color-alt);
  padding-bottom: 0;
}
.title {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 2 / 8;
  }
}
.text {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 2 / 8;
  }
}
.quote,
.last-img {
  grid-column: 1 / -1;

  @include mq(sm) {
    grid-column: 2 / 11;
  }
}
.last-img {
  @include mq($until: sm) {
    position: relative;
    left: calc(var(--spacer-xl) * -1);
    width: calc(var(--spacer-xl) * 2 + 100%);
  }
}
.first-img {
  position: relative;
  grid-column: 1 / -1;
  background: var(--color-accent);
  overflow: hidden;

  @include mq($until: sm) {
    margin-top: var(--spacer-md);
  }

  @include mq(sm) {
    right: calc(var(--spacer-xl) * -1);
    grid-column: 8 / -1;
  }

  img {
    position: relative;
    width: 100%;
  }

  .lines {
    position: absolute;
    left: 70%;
    top: 100%;
    max-width: 45px;

    @include mq($until: sm) {
      display: none;
    }
  }
}
.imgs {
  @include mq($until: sm) {
    display: none;
  }
}

.parallax {
  position: absolute;
  top: -2%;
  right: 0;
  pointer-events: none;
  z-index: 4;
  max-width: 16.166788588149%;

  @include mq($until: sm) {
    max-width: 40%;
  }
}
