.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: var(--spacer-sm) var(--spacer-md);

  &--np {
    padding: 0;
  }
  &--xl {
    max-width: none;
  }
  &--lg {
    max-width: calc(var(--wrapper-lg) + (var(--spacer-md) * 2));
  }
  &--md {
    max-width: calc(var(--wrapper-md) + (var(--spacer-md) * 2));
  }
  &--sm {
    max-width: calc(var(--wrapper-sm) + (var(--spacer-md) * 2));
  }
}

.s {
  &-fh {
    min-height: calc(var(--vh) * 100);
  }
}

.rotate {
  width: 0;
  transform: rotate(-90deg) translateY(50%);
  transform-origin: 100% 100%;

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1em;
    white-space: nowrap;

    transform: translate3d(0, -50%, 0);
  }
}

.rotate--reverse {
  width: 0;
  transform: rotate(90deg) translateY(-50%);
  transform-origin: 0% 0%;

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1em;
    white-space: nowrap;

    transform: translate3d(0, 50%, 0);
  }
}

.select {
  position: relative;
  display: inline-block;
  width: 20px;
  margin-left: 5px;
  align-self: center;
  top: -1px;
  @include transition(top);

  &::before {
    content: '';
    width: 10px;
    position: absolute;
    height: 1px;
    transform: rotate(45deg);
    background: currentColor;
    @include transition(transform);
  }
  &::after {
    content: '';
    width: 10px;
    top: 50%;
    position: absolute;
    height: 1px;
    transform: rotate(-45deg);
    background: currentColor;
    @include transition(transform);
  }

  &.dropdown {
    top: 0px;

    &::before {
      transform: rotate(45deg) scaleX(0.5) translateX(-50%);
    }
    &::after {
      transform: rotate(-45deg) scaleX(0.5) translateX(50%);
    }
  }
}

.t {
  &-center {
    text-align: center
  }

  &-uc {
    text-transform: uppercase;
  }
}

.color {
  &--primary { @extend %color--primary; }
  &--dark { @extend %color--dark; }
  &--white { @extend %color--white; }
  &--light { @extend %color--light; }
  &--muted { @extend %color--muted; }
  &--alt { @extend %color--alt; }
  &--accent { @extend %color--accent; }
  &--purple { @extend %color--purple; }
}
.em--color {
  &--primary em { @extend %color--primary; }
  &--dark em { @extend %color--dark; }
  &--white em { @extend %color--white; }
  &--light em { @extend %color--light; }
  &--muted em { @extend %color--muted; }
  &--alt em { @extend %color--alt; }
  &--accent em { @extend %color--accent; }
  &--purple em { @extend %color--purple; }
}

.a--color {
  &--primary a { @extend %color--primary; }
  &--dark a { @extend %color--dark; }
  &--white a { @extend %color--white; }
  &--light a { @extend %color--light; }
  &--muted a { @extend %color--muted; }
  &--alt a { @extend %color--alt; }
  &--accent a { @extend %color--accent; }
  &--purple a { @extend %color--purple; }
}