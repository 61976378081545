@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";
























































































.parallax {
  position: absolute;
  top: 2%;
  right: 2%;
  pointer-events: none;
  z-index: 4;
  max-width: 20%;

  @include mq($until: sm) {
    display: none;
  }

  @include mq(sm) {
    top: -18%
  }

  img {
    position: relative;
    z-index: 1;

    &.leaf {
      z-index: 30;
      pointer-events: none;
    }
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-45%, -40%, 0) scale(0.62);
    width: 100%;
    height: 0;
    background: var(--color-primary);
    border-radius: 50%;
    padding-bottom: 100%;
  }
}

section {
  background: var(--gradient);
}

.grid {
  padding-bottom: var(--spacer-md);
  
  @include mq(sm) {
    padding-top: var(--spacer-xl);
    padding-bottom: var(--spacer-xl);
  }
}

article {
  grid-column: 1 / -1;
  @include mq($until: sm) {
    & + article {
      margin-top: var(--spacer-xl);
    }
  }

  @include mq(sm) {
    padding: var(--spacer-md);
    grid-column: auto / span 4;
  }
}

.default {
  img.animated {
    transition: 1s transform var(--ease);
  }
  &:hover {
    img.animated {
      transform: scale(1.1);
    }

    figcaption {
      &::before {
        transform: scaleX(0.5);
      }
    }
  }
}

.figure {
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

figcaption {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 33.33%;
    height: 5px;
    background: var(--color-alt);
    transition: 1s transform var(--ease);
    transform-origin: 0% 50%;

    @include mq($until: sm) {
      top: -8px;
      height: 4px;
    }
  }
}

.title {
  grid-column: 1 / -1;
  text-align: left;

  @include mq(sm) {
    text-align: center;
    grid-column: 4 / 10;
  }
}
