%typo {
  &--xxs {
    font-family: var(--font-head);
    font-size: var(--typo-xs);
    font-weight: var(--weight-demi);
    line-height: var(--typo-xs);
  }
  &--xs {
    font-family: var(--font-base);
    font-size: var(--typo-xs);
    font-weight: var(--weight-regular);
    line-height: calc(var(--typo-xs) * 1.25);
  }
  &--sm {
    font-family: var(--font-base);
    font-size: var(--typo-sm);
    font-weight: var(--weight-regular);
    line-height: calc(var(--typo-sm) * 2);
  }
  &--sm-2 {
    font-family: var(--font-base);
    font-size: var(--typo-md);
    font-weight: var(--weight-regular);
    line-height: calc(var(--typo-md) * 1.5);
  }
  &--md {
    font-family: var(--font-base);
    font-size: var(--typo-xl);
    font-weight: var(--weight-demi);
    text-transform: uppercase;
    line-height: calc(var(--typo-xl) * 1.2);
  }
  &--md-2 {
    font-family: var(--font-head);
    font-size: var(--typo-md);
    font-weight: var(--weight-demi);
    line-height: calc(var(--typo-md) * 1.5);
  }
  &--lg {
    font-family: var(--font-head);
    font-size: var(--typo-lg);
    font-weight: var(--weight-regular);
    line-height: calc(var(--typo-lg) * 1.4);
  }
  &--xl {
    font-family: var(--font-head);
    font-size: var(--typo-xl);
    font-weight: var(--weight-regular);
    line-height: calc(var(--typo-xl) * 1.22);
  }
  &--xxl {
    font-family: var(--font-head);
    font-size: var(--typo-xxl);
    font-weight: var(--weight-regular);
    line-height: calc(var(--typo-xxl) * 1.22);
  }
  &--xxxl {
    font-family: var(--font-head);
    font-size: var(--typo-xxxl);
    font-weight: var(--weight-regular);
    line-height: calc(var(--typo-xxxl) * 1.1);
  }
}

%color {
  &--primary { color: var(--color-primary) }
  &--dark { color: var(--color-dark) }
  &--white { color: var(--color-white) }
  &--light { color: var(--color-light) }
  &--muted { color: var(--color-muted) }
  &--alt { color: var(--color-alt) }
  &--accent { color: var(--color-accent) }
  &--purple { color: var(--color-purple) }
}

%cta {
  display: inline-block;
  position: relative;

  @extend %typo--xxs;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  display: inline-block;

  & > a,
  & > button {
    display: inline-block;
    position: relative;
    pointer-events: auto;
    padding: var(--spacer-xxs) 0;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background: var(--color-alt);
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background: var(--color-light);
      transform: scaleX(0);
      transform-origin: 0% 50%;
      @include transition(transform);
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    * {
      cursor: pointer;
      pointer-events: none;
    }
  }
}