@import "~sass-mq"; @import "@/assets/scss/main.scss"; @import "@/assets/scss/core/_typo.scss";


























































































section {
  background: var(--color-alt);

  @include mq($until: sm) {
    padding-bottom: 100px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 45%;
    background: var(--color-purple);
  }

  .parallax {
    position: absolute;
    pointer-events: none;
    z-index: 4;
    max-width: 16.166788588149%;

    &.parallax-1 {
      left: 0;
      top: -10%;

      @include mq($until: sm) {
        display: none;
      }
    }

    &.parallax-2 {
      right: 0;
      top: -2%;

      @include mq($until: sm) {
        max-width: 40%;
      }
    }
  }

  .imgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;


    & > * {
      position: absolute;
      top: 45%;

      @include mq($until: sm) {
        max-width: 72px;
      }
    }

    .first {
      left: 0;
      transform: translate3d(0, 0%, 0);

      @include mq($until: sm) {
        transform: translate3d(-50%, 0%, 0);
      }
    }
    .last {
      right: 0;
      transform: translate3d(0, -100%, 0);

      @include mq($until: sm) {
        transform: translate3d(50%, -100%, 0);
      }
    }

    .rhombus {
      bottom: 10%;
      left: 10%;
      top: auto;
      width: auto;
      max-width: 60px;

      @include mq($until: sm) {
        bottom: 10px;
      }
    }
  }

  .grid {
    padding-top: var(--spacer-xl);
    position: relative;
    z-index: 1;
  }

  .title {
    grid-column: 1 / -1;
    text-align: left;

    @include mq(sm) {
      text-align: center;
      grid-column: 4 / 10;
    }
  }

  .text,
  .form {
    grid-column: 1 / -1;

    @include mq(sm) {
      grid-column: 4 / 10;
    }

    @include mq(lg) {
      grid-column: 5 / 9;
    }
  }

  .product {
    position: relative;
    grid-column: 1 / -1;
    padding-top: var(--spacer-xxl);
    padding-bottom: var(--spacer-md);

    @include mq(sm) {
      padding-bottom: var(--spacer-xxl);
    }

    .inner {
      position: relative;
      max-width: 700px;
      margin: 0 auto;

      @include mq(lg) {
        max-width: 800px;
      }
    }

    img {
      margin: 0 auto;
      max-width: 420px;
      width: 100%;
    }
  }

  .labels {
    @include mq($until: sm) {
      margin-top: var(--spacer-md);
    }
    @include mq(sm) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    font-size: var(--typo-md);

    @include mq(lg) {
      font-size: var(--typo-lg);
    }


    .label {
      position: absolute;

      .dot {
        position: relative;
        width: 35px;
        height: 35px;
        border: 1px solid var(--color-white);
        border-radius: 50%;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--color-primary);
          border-radius: 50%;
          transform: scale(0.5);
          transition: 1s transform var(--ease);
        }
      }

      @include mq($until: sm) {
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-right: 45px;

        .dot {
          order: -1;
          margin-right: 15px;
          width: 25px;
          height: 25px;
        }
      }

      @include mq(sm) {
        cursor: pointer;

        span {
          position: relative;
          display: inline-block;
          transition: 0.6s all var(--ease) 0.1s;
        }

        &:hover {
          .dot {
            &::after {
              transform: scale(0.8);
            }
          }
          span {
            color: var(--color-muted);
          }
        }

        &:nth-child(1) {
          top: 10%;
          left: -5%;
          text-align: right;
          .dot {
            margin-left: var(--spacer-md);
          }
          &:hover {
            span {
              transform: translate3d(10px, 0, 0);
            }
          }
        }
        &:nth-child(2) {
          top: 10%;
          right: -15%;
          span {
            order: 2;
          }
          .dot {
            margin-right: var(--spacer-md);
          }
          &:hover {
            span {
              transform: translate3d(-10px, 0, 0);
            }
          }

          body.lang-de-de & {
            right: -24%;
          }

          body.lang-en-gb & {
            right: -7%;
          }
        }
        &:nth-child(3) {
          top: 50%;
          right: -15%;
          span {
            order: 2;
          }
          .dot {
            margin-right: var(--spacer-md);
          }
          &:hover {
            span {
              transform: translate3d(-10px, 0, 0);
            }
          }

          body.lang-de-de & {
            right: -27%;
          }

          body.lang-en-gb & {
            right: -15%;
          }
        }
        &:nth-child(4) {
          top: 85%;
          right: -5%;
          span {
            order: 2;
          }
          .dot {
            margin-right: var(--spacer-md);
          }
          &:hover {
            span {
              transform: translate3d(-10px, 0, 0);
            }
          }

          body.lang-en-gb & {
            right: -22%;
          }

          body.lang-de-de & {
            right: -24%;
          }
        }
        &:nth-child(5) {
          top: 85%;
          left: -12%;
          text-align: right;
          .dot {
            margin-left: var(--spacer-md);
          }
          &:hover {
            span {
              transform: translate3d(10px, 0, 0);
            }
          }
        }
        &:nth-child(6) {
          top: 50%;
          left: -10%;
          text-align: right;
          .dot {
            margin-left: var(--spacer-md);
          }
          &:hover {
            span {
              transform: translate3d(10px, 0, 0);
            }
          }

          body.lang-de-de & {
            left: -15%;
          }
        }

      }

    }
  }
}

button {
  display: block;
  position: relative;
  appearance: none;
  border: 0;
  height: 45px;
  width: 100%;
  padding: 0 15px;
  font-family: var(--font-base);
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  background: var(--color-primary);
  color: var(--color-light);
  border-radius: 23px;
  cursor: pointer;
  z-index: 20;
  max-width: 300px;
  margin: 0 auto;

  @include mq(sm) {
    height: 55px;
    padding: 0 20px;
  }
}
